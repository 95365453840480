<template>
  <div class="n-steps-container">
    <n-steps :current="current" status="process">
      <n-step title="不联网" description="本应用无需联网，不会上传任何数据" status="process" />
      <n-step title="自由的卸载" description="干净的删除" status="process" />
      <n-step title="护眼" description="提醒你休息" status="process" />
      <n-step title="完成" description="" status="process" />
    </n-steps>
  </div>
</template>

<script>
import { ref } from 'vue'
import { NSteps, NStep } from 'naive-ui'

export default {
  components: {
    NSteps,
    NStep
  },
  setup() {
    const current = ref(1)
    return { current }
  }
}
</script>

<style scoped>
.n-steps-container {
  max-width: 80%;
  width: 60%;
  margin: auto;
}


.feature-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed to fill the screen or container */
}

/* Ensure that the direct child of the container is also centered if it is a flex container */
.feature-section-container > * {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
