<template>
  <div class="changelog-container">
    <n-timeline>
      <n-timeline-item
        v-for="entry in changelogEntries"
        :key="entry.version"
        :type="entry.type"
        :title="`版本 ${entry.version}`"
        :content="entry.description"
        :time="entry.date"
      />
    </n-timeline>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { NTimeline, NTimelineItem } from 'naive-ui';

const changelogEntries = ref([
  // Your changelog entries here
  {
    version: '0.0.0',
    description: '准备中',
    date: '2024-11-10',
  },
  {
    version: '0.0.1',
    description: '测试发布',
    date: '2024-04-12',
    type: 'success'
  }
  // Add more entries as needed
]);
</script>

<style scoped>
.changelog-container {
  max-width: 10%;
  margin: auto;
}

/* You can adjust the timeline item styles as needed */
.n-timeline-item {
  /* Your custom styles here */
}
</style>
