<template>
  <div class="centered-container">
    <n-gradient-text size="large">
      一个吊儿郎当的打工仔。
    </n-gradient-text>
  </div>
</template>

<script>
import { NGradientText } from 'naive-ui';

export default {
  components: {
    NGradientText,
  },
};
</script>

<style scoped>
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* 使用视口高度确保元素在屏幕中垂直居中 */
  text-align: center; /* 文本水平居中 */
}

/* 如果需要，可以进一步定制NGradientText的样式 */
.n-gradient-text {
  /* 定制渐变文字样式，如字体大小、颜色等 */
  font-size: 22rem; /* 调整字体大小 */
  background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  -webkit-background-clip: text;
  color: transparent;
}
</style>
