<template>
  <div>
    <n-carousel autoplay>
      <n-carousel-item>
        <div class="carousel-slide">
    <div class="content">
      <h2>护眼小工具</h2>
      <div class="version-info">
        <p>版本: 0.0.1</p>
        <p>更新日期: 2024-04-12</p>
        <p>文件大小: 1.1MB</p>
      </div>
      <a href="EyesSaverInstall.msi" class="download-button" download>立即下载</a>
    </div>
  </div>
      </n-carousel-item>
      <n-carousel-item>
        <img class="carousel-img" :src="reminderScreenshot" alt="提醒界面截图" />
      </n-carousel-item>
    </n-carousel>
    <FeatureSection />
  </div>
</template>

<script>
import { NCarousel, NCarouselItem } from 'naive-ui';
import FeatureSection from '@/components/FeatureSection.vue';
import reminderScreenshot from '@/assets/reminder-screenshot.png';

export default {
  components: {
    NCarousel,
    NCarouselItem,
    FeatureSection
  },
  data() {
    return {
      reminderScreenshot,
    };
  }
};
</script>


<style scoped>
.n-carousel {
  max-width: 80%; /* 最大宽度，也可以设置为一个固定值 */
  height: auto; /* 自适应高度 */
  margin: 2rem auto; /* 居中且有一些边距 */
  background-color: #f0f4f8; /* 浅灰色背景 */
  box-shadow: 0 4px 10px rgba(0,0,0,0.1); /* 轻微的阴影效果 */
  border-radius: 10px; /* 轮播边框圆角 */
}
.carousel-slide {
  position: relative;
  text-align: center;
  padding: 2rem;
  background: none; /* 透明背景或根据需要设置 */
  color: #333; /* 深色文字，易于阅读 */
}
.carousel-img {
  width: 100%;
  height: auto; /* 自适应高度 */
  object-fit: cover; /* 覆盖满整个容器，保持图片比例 */
  border-radius: 10px; /* 图片边框圆角 */
}
.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* or your desired height */
  background-color: #f0f4f8; /* the dark blue background */
  color: rgb(51, 39, 39);
  padding: 20px;
}

.content {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1); /* Slight white overlay */
  padding: 20px;
  border-radius: 10px;
}

.version-info p {
  margin: 5px 0; /* Spacing between version info lines */
}

.download-button {
  padding: 0.8em 2em; /* 更大的按钮填充 */
  background-color: #0056b3; /* 按钮颜色 */
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #007bff; /* 鼠标悬停时按钮颜色 */
}

/* 文字覆盖层样式 */
.carousel-text-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  border-radius: 0 0 10px 10px; /* 下方圆角 */
}
</style>

