<template>
    <nav>
      <router-link to="/">首页</router-link>
      <router-link to="/help">帮助</router-link>
      <router-link to="/changelog">更新日志</router-link>
      <router-link to="/about">关于我们</router-link>
    </nav>
  </template>

<style scoped>
nav {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  height: 60px;            /* 导航栏高度 */
}

nav a {
  margin: 0 20px;          /* 两侧的间距 */
  font-size: 1.5em;        /* 字体大小 */
  text-decoration: none;   /* 去掉下划线 */
}

/* 如果你想要特定的链接样式 */
.router-link-exact-active {
  font-weight: bold;
}
</style>
