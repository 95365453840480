<template>
  <div class="video-container">
    <video controls width="320"> <!-- 设置视频播放器宽度为320px -->
      <source src="护眼小工具演示视频1.0.mp4" type="video/mp4">
      您的浏览器不支持视频标签。
    </video>
  </div>
</template>

<script>
export default {
  name: 'HelpView'
  // 如果需要的话，您可以在这里添加脚本
};
</script>

<style scoped>
.video-container {
  max-width: 620px; /* 限制视频容器的最大宽度为320px */
  margin: auto; /* 将视频容器居中显示 */
}
video {
  width: 100%; /* 视频宽度充满整个容器 */
  height: auto; /* 视频高度自动调整 */
}
</style>
